<template>
  <div>
    <template v-if="!loading">
      <div class="row mb-3">
        <div class="col-md-12">
          <a href="#" @click.prevent="" class="btn btn-info float-end py-1"
            >Status: {{ request.status }}</a
          >
          <h3>Request #{{ request.id }}</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <h4 class="text-muted">User Information</h4>
              <div class="row">
                <div class="col-md-12">
                  <label>Name</label>
                  <p>{{ request.name }}</p>
                </div>
                <div class="col-md-12">
                  <label>Email</label>
                  <p>{{ request.email }}</p>
                </div>
                <div class="col-md-12">
                  <label>Phone Number</label>
                  <p>{{ request.phone_number }}</p>
                </div>
                <div class="col-md-12">
                  <label>Requested</label>
                  <p>{{ request.created_at }}</p>
                </div>
              </div>
              <hr />
              <div class="row" v-if="request.status == 'pending'">
                <div class="col-md-6">
                  <a
                    href="#"
                    @click.prevent="setAction('approve')"
                    class="btn btn-success d-grid"
                    >Approve</a
                  >
                </div>
                <div class="col-md-6">
                  <a
                    href="#"
                    @click.prevent="destroy"
                    class="btn btn-danger d-grid"
                    >Delete</a
                  >
                </div>
              </div>
              <div class="row" v-if="request.status == 'approved'">
                <div class="col-md-12">
                  <alert type="info">
                    <p>Waiting for payment.</p>
                    <a
                      href="#"
                      @click.prevent="setAction('resend')"
                      class="text-tiny"
                      >Resend Payment Prompt</a
                    >
                  </alert>
                </div>
                <div class="col-md-6">
                  <a
                    href="#"
                    @click.prevent="setAction('pay')"
                    class="btn btn-success d-grid"
                    >Set paid</a
                  >
                </div>
                <div class="col-md-6">
                  <a
                    href="#"
                    @click.prevent="destroy"
                    class="btn btn-danger d-grid"
                    >Delete</a
                  >
                </div>
              </div>
              <div class="row" v-if="request.status == 'active'">
                <div class="col-md-12">
                  <a
                    href="#"
                    @click.prevent="setAction('complete')"
                    class="btn btn-success d-grid"
                    >Mark Complete</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="card">
            <div class="card-body">
              <h4 class="text-muted">Property Details</h4>
              <div class="row">
                <div class="col-md-6">
                  <label>Package</label>
                  <p>
                    <a href="#" @click.prevent="show_package_details = true"
                      >{{ request.package.name }} - <sub class="ms-3">KES</sub
                      >{{ request.package.amount }}</a
                    >
                  </p>
                </div>
                <div class="col-md-6">
                  <label>Category</label>
                  <p>{{ request.category }}</p>
                </div>
                <div class="col-md-6">
                  <label>Region</label>
                  <p>{{ request.region }}</p>
                </div>
                <div class="col-md-12">
                  <label>Property Details</label>
                  <p>{{ request.category_details }}</p>
                </div>
                <div class="col-md-12">
                  <label>Region Details</label>
                  <p>{{ request.region_details }}</p>
                </div>
                <div class="col-md-12" v-if="request.status == 'active'">
                  <label>Assigned to</label>
                  <p>
                    <router-link
                      :to="{
                        name: 'admin.users.show',
                        params: { id: request.user.id },
                      }"
                      >{{ request.user.name }}</router-link
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="card mt-3" v-if="request.status == 'paid'">
            <div class="card-body">
              <form @submit.prevent="assign">
                <div class="form-group">
                  <label>Assign Manager</label>
                  <v-select
                    :options="users"
                    label="name"
                    class="form-control mt-2"
                    placeholder="Select Field Manager"
                    v-model="selected_user"
                  />
                </div>
                <div class="form-group">
                  <button class="btn btn-primary">
                    <i class="lni lni-save me-2"></i>Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </template>
    <loading v-if="loading" />
    <modal :show="show_package_details" @close="show_package_details = false">
      <template slot="header">Package Details</template>
      <div class="text-center">
        <h4>{{ request.package.name }}</h4>
        <p class="text-muted mb-0">KES</p>
        <h3>{{ request.package.amount }}</h3>
        <ul>
          <li
            v-for="(item, i) in request.package.content"
            :key="`package-${i}`"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      loading: true,
      request: { package: {} },
      show_package_details: false,
      users: [],
      selected_user: null,
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios
        .get(`/api/v1/admin/relationship-managers/${this.$route.params.id}`)
        .then((response) => {
          this.request = response.data.request;
          this.users = response.data.users;
          this.loading = false;
        });
    },

    assign() {
      let data = {
        user_id: this.selected_user.id,
        action: "assign",
      };

      this.$axios
        .put(`/api/v1/admin/relationship-managers/${this.$route.params.id}`, data)
        .then(() => {
          this.fetch();
        });
    },

    setAction(action) {
      let data = {
        action: action,
      };

      this.$axios
        .put(`/api/v1/admin/relationship-managers/${this.$route.params.id}`, data)
        .then(() => {
          this.fetch();
        });
    },

    destroy() {
      this.$axios
        .delete(`/api/v1/admin/relationship-managers/${this.$route.params.id}`)
        .then(() => {
          this.$router.push({ name: "admin.relationship-managers.index" });
        });
    },
  },
};
</script>
